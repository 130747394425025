<template>
  <div class="page-orderindex">
    <div class="content">
      <div class="tabs">
        <div
          class="tab"
          v-for="(item, index) in tabs"
          :key="index"
          @click="changeTabs(index)"
          :class="tab === index ? 'active' : ''"
        >
          {{ item.title }}
        </div>
        <div class="tab-search">
          <b-input-group>
            <template #append>
              <b-button icon="secondary" @click="getList"
                ><b-icon style="font-size: 14px" icon="search"></b-icon
              ></b-button>
            </template>
            <b-form-input
              placeholder="请输入商品名称"
              v-model="goods_name"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>
      <div class="top-tooltip">
        <div style="width: 400px" class="it">订单详情</div>
        <div style="width: 150px" class="it">专家费</div>
        <div style="width: 150px" class="it">操作费</div>
        <div style="width: 150px" class="it">商品总额</div>
        <div style="width: 150px" class="it">订单状态</div>
        <div style="width: 160px" class="it">操作</div>
      </div>
      <div class="list">
        <b-overlay :show="show">
          <div
            class="item"
            v-for="(item, index) in list"
            :key="index"
            @click="toInfo(item.order_bn)"
          >
            <div class="item-header">
              <span>
                {{ $timeFormat(item.createtime, "yy-mm-dd hh:MM:ss") }}
              </span>
              <span>订单编号：{{ item.order_bn }}</span>
            </div>
            <div class="item-content" v-for="(child, i) in item.items" :key="i">
              <div class="table-info">
                <img :src="$host + child.image_id" />
                <div class="data">
                  <div class="data-title">{{ child.name }}</div>
                  <div class="data-num">数量 x {{ child.nums }}</div>
                  <div class="data-price">￥{{ child.price }}</div>
                </div>
              </div>
              <div class="table-nums">
                {{ item.expert_fees }}
              </div>
              <div class="table-nums">
                {{ item.operation_fee }}
              </div>
              <div class="table-nums">
                {{ item.order_total }}
              </div>
              <div class="table-nums">
                {{ item.order_status }}
              </div>
              <div class="table-tools">
                <div v-if="item.status == 's1'" class="tool-opera">找人代付</div>
                <div v-if="item.status == 's1'" class="tool-opera">立即付款</div>
                <div v-if="item.status == 's1'" class="tool-opera">取消订单</div>
                <div
                  v-if="item.status == 's5'"
                  class="tool-opera"
                  @click.stop="toChat(item.order_bn)"
                >
                  立即评价
                </div>
                <div v-if="item.status == 's3'" class="tool-opera">确认到院</div>
              </div>
            </div>
          </div>
          <div v-if="list.length == 0">
            <Empty></Empty>
          </div>
          <div style="margin: 20px 0" v-if="list.length > 0">
            <b-pagination
              v-model="page"
              :per-page="limit"
              :total-rows="count"
            ></b-pagination>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabs: [
        { title: "全部", status: "" },
        { title: "待付款", status: "s1" },
        { title: "待发货", status: "s2" },
        { title: "待收货", status: "s3" },
        { title: "待评价", status: "s5" },
        { title: "退款/售后", status: "s6" },
      ],
      tab: 0,
      limit: 5,
      page: 1,
      goods_name: "",
      list: [],
      show: false,
      count: 0,
    };
  },
  mounted() {
    this.getList();
  },
  watch: {
    tab: function () {
      this.getList();
    },
    page: function () {
      this.getList();
    },
  },
  methods: {
    changeTabs(i) {
      if (i === this.tab) return false;
      this.tab = i;
    },
    getList() {
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 500);
      let data = {
        status: this.tabs[this.tab].status,
        page: this.page,
        limit: this.limit,
        goods_name: this.goods_name,
      };
      this.$http.myorders(data).then((res) => {
        console.log(res);
        let { data, code, msg } = res;
        if (code == 1) {
          this.count = data.total;
          data.data.forEach((item) => {
            switch (item.status) {
              case "s1":
                item.order_status = "待付款";
                break;
              case "s2":
                item.order_status = "待发货";
                break;
              case "s3":
                item.order_status = "待收货";
                break;
              case "s4":
                item.order_status = "已退款";
                break;
              case "s5":
                item.order_status = "待评论";
                break;
              case "s6":
                item.order_status = "已完成";
                break;
              case "s7":
                item.order_status = "已作废";
                break;
            }
          });
          this.list = data.data;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    toChat(order) {
      this.$router.push({
        path: "/order_comment?order=" + order,
      });
    },
    toInfo(event) {
      this.$router.push({
        path: "/order_info?order=" + event,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./order.scss";
</style>
